/** CSS for Standard Grid START */

.sidebar {
  grid-area: sidebar;
}

.content {
  grid-area: content;
}

.header {
  grid-area: header;
  position: sticky;
}

.footer {
  grid-area: footer;
}

.grid-wrapper {
  display: grid;
  grid-gap: 10px;
  /* grid-template-columns: 12vw 85vw; */
  grid-template-columns: 76px 1200px;
  grid-template-areas:
    "sidebar header"
    "sidebar content";
  justify-content: center;
}

.box {
  border-radius: 5px;
  padding: 10px;
  font-size: 150%;
}

/** CSS for Standard Grid END */

/** CSS for Summary Grid START */

.summary-left-content {
  grid-area: 1 / 1 / span 0 / span 0;
}

.summary-right-content {
  grid-area: 1 / 2 / span 0 / span 0;
}

.summary-header {
  grid-area: header;
  grid-column: col;
  position: sticky;
}

.summary-grid-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 65vw 35vw;
  grid-template-areas:
    "summary-header"
    "summary-left-content"
    "summary-right-content";
}

/** CSS for Summary Grid END */

h1 {
  margin: 0;
}

body {
  overflow-x: hidden;
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px)
    env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter";
  padding-top: 50px;
}

.app h2 {
  font-size: 1.2rem;
  font-weight: normal;
}

.app h2-bold {
  font-size: 1.2rem;
  font-weight: bold;
}

.app h3 {
  text-shadow: 1px 1px 4px black;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.app h3 b {
  color: rgb(252, 212, 33);
}

.app h4 {
  font-size: 25px;
}

.app h5 {
  font-size: 20px;
  font-weight: normal;
  align-items: center;
}

.app h5-bold {
  color: black;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
}

.app h6 {
  font-size: 18px;
  font-weight: 500;
}

.app h7 {
  font-size: 15px;
}

.nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
}

.button-img {
  display: inline-block;
  vertical-align: left;
  margin-right: 35px;
  width: 30px;
  height: 30px;
}

.videos div {
  display: block;
  object-fit: cover;
  flex-direction: column-reverse;
  align-items: center;
}

.video-js {
  background-color: rgb(116, 116, 116) !important;
  margin-top: 20px !important;
  margin-right: 20px;
  width: 200px;
  height: 150px;
}

.video-js .vjs-current-time {
  display: block !important;
}

.video-recording-container {
  min-height: 100%;
  /* position: relative; */
}

.video-container {
  position: relative;
}
textarea::placeholder {
  font-size: 16px;
  font-family: "Inter";
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.active {
  display: flex;
}

.webcam-background-view-horizontal-alignment {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
}

.form-vertical {
  margin-top: -10px;
  width: 300px;
}

.comment-write-text-area {
  width: 250px;
  height: 50px;
}

.time-wrapper {
  position: relative;
  width: 80px;
  height: 60px;
  font-size: 48px;
}

.time-wrapper .time {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  opacity: 1;
}

.time-wrapper .time.up {
  opacity: 0;
  transform: translateY(-100%);
}

.time-wrapper .time.down {
  opacity: 0;
  transform: translateY(100%);
}

/* Authentication Modal CSS Classes */

.authentication-modal {
  z-index: 12345;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.authentication-modal-main {
  position: fixed;
  background: white;
  min-width: 30%;
  min-height: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.authentication-modal-text {
  color: black;
  text-align: center;
  margin-top: 40px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.playback-container {
  margin: 10px;
}

.overflow-scroll {
  overflow-y: scroll !important;
}

@media (display-mode: picture-in-picture) {
  body {
    background: #363636;
  }
}

/* Gradient BG */
@property --gradientBGColor1 {
  syntax: "<color>";
  initial-value: #1da9e6;
  inherits: false;
}

@property --gradientBGColor2 {
  syntax: "<color>";
  initial-value: #6966fe;
  inherits: false;
}

.gradientBg {
  background: linear-gradient(
    90deg,
    var(--gradientBGColor1),
    var(--gradientBGColor2) 82%
  ) !important;
  transition:
    --gradientBGColor1 0.3s,
    --gradientBGColor2 0.3s;
}

.gradientBg:hover {
  --gradientBGColor1: #2a6dbd !important;
  --gradientBGColor2: #4240b3 !important;
}
/* End Gradient BG */
