.uploadModal {
  padding: 20px;
}

@keyframes bgcolor {
  0% {
    background-color: white;
  }
  50% {
    background-color: rgb(230, 255, 230);
  }
  100% {
    background-color: white;
  }
}
.showDropActive {
  animation-name: bgcolor;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.progressBarContainer {
  border: 1px dashed #eff0f2;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.progressBarFileIndicator {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.dragDropCaption {
  font-weight: normal;
  color: #aeb5bd;
}
