:root {
  --border-light-gray: #e9ecef;
  --primary_purple: #4e4cbb;
  --primary_hover: #6966fd;
  --purple_2: #9b99ff;
  --purple_1: #e6e5ff;

  --secondary_hover: #0884ba;
  --secondary: #1da9e6;

  --default_gradient: linear-gradient(90deg, #1da9e6 0%, #6966fe 82%);
  --default_gradient_slanted: linear-gradient(72deg, #1da9e6 0%, #6966fe 82%);
  --dark_gradient: linear-gradient(70deg, #2a6dbd 1%, #4240b3 98%);

  --dark6: #343a40;
  --dark5: #495057;
  --dark4: #6c757d;
  --dark3: #ced4da;
  --dark2: #e9ecef;
  --dark1: #f5f5f5;

  --white: #ffffff;
  --black: #111111;

  --redError: #cd4631;
  --greenSuccess: #2cae48;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
  This is to account for the safe area inset on iOS
  https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/adaptivity-and-layout/
*/
html {
  min-height: calc(100% + env(safe-area-inset-top));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
